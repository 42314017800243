import request from '@/utils/request';
/**
 * @description admin账户登录
 * @param {Object} data admin登录的账户密码
 */

export function login(data) {
  return request({
    url: '/admin/sys/login',
    method: 'post',
    data: data
  });
}
export function getQuickReplyList() {
  return request({
    url: '/admin/config/user/list',
    method: 'get',
    params: {
      code: 11
    }
  });
}
export function deleteQuickReply(data) {
  return request({
    url: '/admin/config/user/delete',
    method: 'post',
    data: data
  });
}
export function addQuickReply(value) {
  var data = {
    value: value,
    code: 11
  };
  return request({
    url: '/admin/config/user/save',
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: '/admin/sys/user',
    method: 'get'
  });
}
export function getKeywordMessage(data) {
  return request({
    url: '/admin/message/message/chat/list',
    method: 'post',
    data: data
  });
}
export function deleteUser(params) {
  return request({
    url: '/admin/sys/super/delete',
    method: 'get',
    params: params
  });
}
export function getImUserInfo() {
  return request({
    url: '/admin/sys/auth/sign',
    method: 'get'
  });
}
export function skillDownRecord(data) {
  return request({
    url: '',
    method: 'post',
    data: data
  });
}
/**
 * @description 退出登录
 */

export function logout() {
  return request({
    url: '/admin/sys/logout',
    method: 'post'
  });
}
/**
 * @description 搜索用户
 * @param {Object} data
 */

export function getList(data) {
  return request({
    url: '/admin/sys/super/search',
    method: 'post',
    data: data
  });
}
/**
 * @description 注册账号
 * @param {Object} data
 */

export function register(data) {
  return request({
    url: '/admin/sys/super/register',
    method: 'post',
    data: data
  });
}
/**
 * @description 修改账号
 * @param {Object} data
 */

export function update(data) {
  return request({
    url: '/admin/sys/super/modify',
    method: 'post',
    data: data
  });
}
/**
 * @description 修改密码
 * @param {Object} data
 */

export function modifyPassword(data) {
  return request({
    url: '/admin/sys/password',
    method: 'post',
    data: data
  });
}