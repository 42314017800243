var state = {
  columnList: []
};
var mutations = {
  SET_COLUMNS_LIST: function SET_COLUMNS_LIST(state, list) {
    state.columnList = list;
  }
};
var actions = {
  setColumnsList: function setColumnsList(_ref, _ref2) {
    var commit = _ref.commit;
    var list = _ref2.list;
    commit('SET_COLUMNS_LIST', list);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};