import Vue from 'vue';
import Vuex from 'vuex';
import getters from "./getters";
import app from "./modules/app";
import permission from "./modules/permission";
import settings from "./modules/settings";
import admin from "./modules/admin";
import tagsView from "./modules/tagsView";
import column from "./modules/column";
import im from "./modules/im";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    permission: permission,
    settings: settings,
    admin: admin,
    tagsView: tagsView,
    column: column,
    im: im
  },
  getters: getters
});
export default store;