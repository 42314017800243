var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.admin.token;
  },
  avatar: function avatar(state) {
    return state.admin.avatar;
  },
  username: function username(state) {
    return state.admin.username;
  },
  roles: function roles(state) {
    return state.admin.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  language: function language(state) {
    return state.app.language;
  },
  // IM userInfo
  userInfo: function userInfo(state) {
    return state.app.userInfo;
  },
  // IM 會話列表已加載
  imDataReady: function imDataReady(state) {
    return state.im.dataLoaded;
  },
  imSdkReady: function imSdkReady(state) {
    return state.im.sdkReady;
  },
  // IM 未读消息数量
  imUnreadMessageCount: function imUnreadMessageCount(state) {
    return state.im.unreadCount;
  },
  // IM 当前选择的会话
  imCurrentConversation: function imCurrentConversation(state) {
    return state.im.selectedConversation;
  },
  // IM 会话列表
  imConversationList: function imConversationList(state) {
    return state.im.conversationList;
  },
  // IM 消息列表
  imMessageList: function imMessageList(state) {
    return state.im.messageListMap;
  }
};
export default getters;