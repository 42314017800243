export default {
  admin: {
    home: 'Home',
    logout: 'Log Out',
    changePassword: 'Change Password',
    oldPassword: 'Origin Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password'
  },
  route: {
    home: 'Home',
    userManage: 'User Manage',
    userList: 'User List',
    userDetail: 'User Detail',
    userSearch: 'User Search',
    permissionManage: 'Permission Manage',
    permissionIndex: 'Permission Manage',
    operationManage: 'Operation Manage',
    sensitiveWords: 'Sensitive Words',
    ReportAudit: 'Report Audit',
    specialPayments: 'Special Payments',
    NegativeOperating: 'Negative Operating',
    skillManage: 'Skill Manage',
    SystemMessage: 'System Message',
    skillSetting: 'Skill Setting',
    homeSkill: 'Index Skill',
    skillAudit: 'Skill Audit',
    auditHistory: 'Audit History',
    orderManage: 'Order Manage',
    orderList: 'Order List',
    homepageColumn: 'Home Page Column',
    columnDetail: 'Column Detail',
    growthOrderList: 'Growth Order',
    miniOrderList: 'Mini Order',
    withdrawList: 'Withdraw List',
    serviceHelper: 'Service Helper',
    ImRecord: 'Im Record',
    PushMessage: 'Push Message',
    downSkillRecord: 'downSkill Record'
  },
  form: {
    search: 'Search',
    clear: 'Clear',
    reset: 'Reset',
    confirm: 'Confirm',
    submit: 'Submit',
    cancel: 'Cancel',
    delete: 'Delete',
    edit: 'Edit',
    disable: 'Disable',
    password: 'Password'
  },
  userList: {
    uuid: 'User ID',
    uuidPlaceholder: 'please input user id'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeAll: 'Close All',
    closeOthers: 'Close Others'
  },
  permission: {
    userName: 'Username',
    email: 'Email',
    searchInput: 'pls input username',
    operation: 'Operation',
    menu: 'Menu',
    createAccount: 'Create Account',
    department: 'Department'
  }
};