import { login as _login, logout as _logout, getInfo as _getInfo } from '@/api/admin';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
import store from '@/store/index';
var state = {
  token: getToken(),
  username: '',
  avatar: '',
  roles: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, username) {
    state.username = username;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  }
};
var actions = {
  // admin login
  login: function login(_ref, adminInfo) {
    var commit = _ref.commit,
        dispatch = _ref.dispatch;
    var username = adminInfo.username,
        password = adminInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        username: username.trim(),
        password: password
      }).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.token);
        setToken(data.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get admin info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo(state.token).then(function (response) {
        var data = response.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        } // data.roles = ['PermissionIndex', 'UserDetail', 'Permission', 'User']


        var menu = data.menu,
            username = data.username,
            avatar = data.avatar; // roles must be a non-empty array

        if (!menu || menu.length <= 0) {
          reject('getInfo: menu must be a non-null string!');
        }

        commit('SET_ROLES', menu);
        commit('SET_NAME', username);
        commit('SET_AVATAR', avatar);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // admin logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        commit('SET_TOKEN', '');
        store.dispatch('app/removeImUserInfo');
        commit('SET_ROLES', []);
        removeToken();
        resetRouter();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      store.dispatch('app/removeImUserInfo');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};