import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/home',
  children: [{
    path: 'home',
    name: 'Home',
    component: function component() {
      return import('@/views/home/index');
    },
    meta: {
      title: 'home',
      icon: 'home'
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [{
  path: '/user',
  component: Layout,
  redirect: '/user/search',
  name: 'User',
  meta: {
    title: 'userManage',
    icon: 'user'
  },
  children: [// {
  //   path: 'list',
  //   name: 'UserList',
  //   component: () => import('@/views/user/list'),
  //   meta: { title: 'userList', icon: 'list' }
  // },
  // {
  //   path: 'detail',
  //   name: 'UserDetail',
  //   component: () => import('@/views/user/detail'),
  //   meta: { title: 'userDetail', icon: 'detail' }
  // },
  {
    path: 'search',
    name: 'UserSearch',
    component: function component() {
      return import('@/views/user/search');
    },
    meta: {
      title: 'userSearch',
      icon: 'search'
    }
  }, {
    path: 'Report-audit',
    name: 'ReportAudit',
    component: function component() {
      return import('@/views/user/Report-audit');
    },
    meta: {
      title: 'ReportAudit',
      icon: 'reportAudit'
    }
  }, {
    path: 'negative-operating',
    name: 'NegativeOperating',
    component: function component() {
      return import('@/views/user/negative-operating');
    },
    meta: {
      title: 'NegativeOperating',
      icon: 'homepage-column'
    }
  }]
}, {
  path: '/skill',
  component: Layout,
  redirect: '/skill/home-skill',
  name: 'Skill',
  meta: {
    title: 'skillManage',
    icon: 'skill'
  },
  children: [{
    path: 'home-skill',
    name: 'HomeSkill',
    component: function component() {
      return import('@/views/skill/home-skill');
    },
    meta: {
      title: 'homeSkill',
      icon: 'home-skill'
    }
  }, {
    path: 'setting',
    name: 'SkillSetting',
    component: function component() {
      return import('@/views/skill/setting');
    },
    meta: {
      title: 'skillSetting',
      icon: 'setting'
    }
  }, {
    path: 'audit',
    name: 'SkillAudit',
    component: function component() {
      return import('@/views/skill/skill-audit');
    },
    meta: {
      title: 'skillAudit',
      icon: 'audit'
    }
  }, {
    path: 'audit-history',
    name: 'AuditHistory',
    component: function component() {
      return import('@/views/skill/audit-history');
    },
    meta: {
      title: 'auditHistory',
      icon: 'history'
    }
  }, {
    path: 'down-skill-record',
    name: 'downSkillRecord',
    component: function component() {
      return import('@/views/skill/down-skill-record');
    },
    meta: {
      title: 'downSkillRecord',
      icon: 'homepage-column'
    }
  }]
}, {
  path: '/content',
  component: Layout,
  redirect: '/content/homepage-column',
  name: 'Content',
  meta: {
    title: 'contentManage',
    icon: 'operation'
  },
  children: [{
    path: 'homepage-column',
    name: 'HomepageColumn',
    component: function component() {
      return import('@/views/content/homepage-column');
    },
    meta: {
      title: 'homepageColumn',
      icon: 'homepage-column'
    }
  }, {
    path: 'sensitive-words',
    name: 'SensitiveWords',
    component: function component() {
      return import('@/views/content/sensitive-words');
    },
    meta: {
      title: 'sensitiveWords',
      icon: 'sensitive-words'
    }
  }, {
    path: 'column-detail',
    name: 'columnDetail',
    hidden: true,
    component: function component() {
      return import('@/views/content/column-detail');
    },
    meta: {
      title: 'columnDetail',
      icon: 'homepage-column'
    }
  }]
}, {
  path: '/order',
  component: Layout,
  redirect: '/order/list',
  name: 'Order',
  meta: {
    title: 'orderManage',
    icon: 'list'
  },
  children: [{
    path: 'list',
    name: 'OrderList',
    component: function component() {
      return import('@/views/order/list');
    },
    meta: {
      title: 'orderList',
      icon: 'list'
    }
  }]
}, {
  path: '/fund',
  component: Layout,
  redirect: '/fund/list',
  name: 'Fund',
  meta: {
    title: 'fundManage',
    icon: 'fund'
  },
  children: [{
    path: 'withdraw-list',
    name: 'withdrawList',
    component: function component() {
      return import('@/views/fund/withdraw-list');
    },
    meta: {
      title: 'withdrawList',
      icon: 'homepage-column'
    }
  }, {
    path: 'special-Payments',
    name: 'SpecialPayments',
    component: function component() {
      return import('@/views/fund/special-Payments');
    },
    meta: {
      title: 'specialPayments',
      icon: 'specialPay'
    }
  }]
}, {
  path: '/information-systems',
  component: Layout,
  name: 'InformationSystem',
  meta: {
    title: 'imInformationSystems',
    icon: 'information-systems'
  },
  children: [{
    path: 'System-message',
    name: 'SystemMessage',
    component: function component() {
      return import('@/views/information-systems/System-message');
    },
    meta: {
      title: 'SystemMessage',
      icon: 'system-message'
    }
  }, {
    path: 'push-message',
    name: 'PushMessage',
    component: function component() {
      return import('@/views/information-systems/push-message');
    },
    meta: {
      title: 'PushMessage',
      icon: 'system-message'
    }
  }, {
    path: 'service-helper',
    name: 'ServiceHelper',
    component: function component() {
      return import('@/views/information-systems/service-helper/index');
    },
    meta: {
      title: 'serviceHelper',
      icon: 'service-helper',
      noKeepLive: true
    }
  }, {
    path: 'im-record',
    name: 'ImRecord',
    component: function component() {
      return import('@/views/information-systems/im-record');
    },
    meta: {
      title: 'ImRecord',
      icon: 'homepage-column',
      noKeepLive: true
    }
  }]
}, {
  path: '/permission',
  component: Layout,
  redirect: '/permission/index',
  name: 'Permission',
  meta: {
    title: 'permissionManage',
    icon: 'permission'
  },
  children: [{
    path: 'index',
    name: 'PermissionIndex',
    component: function component() {
      return import('@/views/permission/index');
    },
    meta: {
      title: 'permissionIndex',
      icon: 'permission'
    }
  }]
}, // {
//   path: '/abandon-discard',
//   component: Layout,
//   redirect: '/abandon-discard/column-detail',
//   name: 'AbandonDiscard',
//   meta: { title: 'abandonDiscard', icon: 'operation' },
//   children: [
//     {
//       path: 'growth-list',
//       name: 'GrowthOrderList',
//       component: () => import('@/views/order/growth-list'),
//       meta: { title: 'growthOrderList', icon: 'list' }
//     },
//     {
//       path: 'mini-list',
//       name: 'MiniOrderList',
//       component: () => import('@/views/order/mini-list'),
//       meta: { title: 'miniOrderList', icon: 'list' }
//     }
//   ]
// },
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;