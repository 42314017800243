/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
export function validEmail(str) {
  return /^([a-zA-Z0-9\w|\-|\.])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(str);
}