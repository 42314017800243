export default {
  admin: {
    home: '首页',
    changePassword: '修改密码',
    logout: '退出',
    oldPassword: '原密码',
    newPassword: '新密码',
    confirmPassword: '确认密码'
  },
  route: {
    home: '首页',
    userManage: '用户管理',
    userList: '用户列表',
    userDetail: '用户详情',
    userSearch: '用户查询',
    contentManage: '内容管理',
    permissionManage: '权限管理',
    permissionIndex: '权限管理',
    fundManage: '资金管理',
    sensitiveWords: '敏感词',
    specialPayments: '特殊付款',
    NegativeOperating: '凍結操作记录',
    skillManage: '技能管理',
    ReportAudit: '举报审核',
    skillSetting: '技能设置',
    SystemMessage: '系统消息',
    PushMessage: 'Push发送',
    homeSkill: '首页技能',
    skillAudit: '技能审核',
    auditHistory: '审核记录',
    orderManage: '订单管理',
    orderList: '订单列表',
    homepageColumn: '首页栏目',
    columnDetail: '栏目详情',
    growthOrderList: '增长订单',
    miniOrderList: 'Mini Order',
    withdrawList: '提现流水',
    imInformationSystems: '消息管理',
    serviceHelper: '客服小助手',
    downSkillRecord: '技能下架记录',
    ImRecord: '反馈报表',
    abandonDiscard: '废弃功能'
  },
  form: {
    search: '搜索',
    clear: '清空',
    reset: '重置',
    confirm: '确认',
    submit: '提交',
    cancel: '取消',
    delete: '删除',
    edit: '编辑',
    disable: '禁用',
    password: '密码'
  },
  userList: {
    uuid: '用户ID',
    uuidPlaceholder: '请输入用户ID'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeAll: '关闭所有',
    closeOthers: '关闭其他'
  },
  permission: {
    userName: '用户名',
    email: '邮箱',
    searchInput: '请输入用户名',
    operation: '操作',
    menu: '菜单',
    createAccount: '创建账户',
    department: '部门'
  }
};