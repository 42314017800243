import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _slicedToArray from "/var/lib/jenkins/workspace/admin.test.3uplay.com/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.object.entries";
import _typeof from "/var/lib/jenkins/workspace/admin.test.3uplay.com/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
export default {
  methods: {
    /**
     * @description 将请求参数中的【特定字符（默认为空字符串,null,undefined）】过滤掉
     * @param {Object} obj
     * @param {Array} stamp 需要过滤的字符集合
     * { name: null, age: 18 }  => { age: 18 }
     * { name: 'tom', age: 0 }  => { name: 'tom', age: 0 }
     * { name: '', age: 18, email: undefined }  => { age: 18 }
     * { checked: false, age: 18 }  => { checked: false, age: 18 }
     */
    filterRequestParams: function filterRequestParams(obj) {
      var stamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['', null, undefined];
      if (_typeof(obj) !== 'object') return obj;
      return Object.entries(obj).reduce(function (result, current) {
        var _current = _slicedToArray(current, 2),
            key = _current[0],
            value = _current[1];

        if (!stamp.includes(value)) {
          result[key] = value;
        }

        return result;
      }, {});
    }
  }
};