import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/var/lib/jenkins/workspace/admin.test.3uplay.com/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang

import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang

import enLocale from "./en";
import zhLocale from "./zh";
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale),
  zh: _objectSpread(_objectSpread({}, zhLocale), elementZhLocale)
};
export function getLanguage() {
  var chooseLanguage = Cookies.get('language');
  if (chooseLanguage) return chooseLanguage; // if has not choose language

  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'en';
}
var numberFormats = {
  zh: {
    currency: {
      style: 'currency',
      currency: 'CNY'
    }
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 3
    }
  }
};
var dateTimeFormats = {
  zh: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  },
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  }
};
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages,
  numberFormats: numberFormats,
  dateTimeFormats: dateTimeFormats
});
export default i18n;