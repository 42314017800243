import "/var/lib/jenkins/workspace/admin.test.3uplay.com/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/lib/jenkins/workspace/admin.test.3uplay.com/node_modules/core-js/modules/es6.promise.js";
import "/var/lib/jenkins/workspace/admin.test.3uplay.com/node_modules/core-js/modules/es6.object.assign.js";
import "/var/lib/jenkins/workspace/admin.test.3uplay.com/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import _i18n from "./lang";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import mixins from "./mixins";
import '@/icons'; // icon

import '@/permission'; // permission control

Vue.use(ElementUI, {
  size: 'mini',
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.mixin(mixins);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});